import React from "react"
import * as S from "./styled"

const Radio = ({ name, label, value }) => {
  return (
    <S.Label>
      {label}
      <input type="radio" name={name} value={value} />
      <S.Checkmark></S.Checkmark>
    </S.Label>
  )
}

export default Radio

import calculateFutureValue from "./calculateFutureValue"
import { getAliquotaImpostoAPagar } from "./getAliquotaImpostoAPagar"

const DIAS_UTEIS_ANUAL = 252
const converteTaxaAnoParaDia = (taxa) => (1 + taxa / 100) ** (1 / DIAS_UTEIS_ANUAL) - 1

const investmentSimulator = (investmentData, cdi, ipca) => {
  const { percdi, impostoRenda, tipo, tempo, inicial, mensal } = investmentData
  const taxaInputUser = Number(percdi.replace(",", "."))
  const totalInvestido = inicial + tempo * mensal
  const imposto = getAliquotaImpostoAPagar(impostoRenda, tempo)
  const cdiAoDia = converteTaxaAnoParaDia(cdi)
  const ipcaAoDia = converteTaxaAnoParaDia(ipca)

  const DIAS_UTEIS_MENSAL = 21
  const MESES_ANO = 12

  const tipoInvestimento = {
    pos: calculateFutureValue(
      (1 + (taxaInputUser / 100) * cdiAoDia) ** DIAS_UTEIS_MENSAL - 1,
      tempo,
      mensal,
      inicial
    ),
    pre: calculateFutureValue(
      (1 + taxaInputUser / 100) ** (1 / MESES_ANO) - 1,
      tempo,
      mensal,
      inicial
    ),
    ipca: calculateFutureValue(
      (1 + (taxaInputUser + ipca) / 100) ** (1 / MESES_ANO) - 1,
      tempo,
      mensal,
      inicial
    ),
  }

  const valorFinalBruto = tipoInvestimento[tipo]

  const rentabilidadeBruta = valorFinalBruto / totalInvestido - 1

  const rentabilidadeLiquida = rentabilidadeBruta * (1 - imposto)
  const rentabilidadeLiquidaAoAno = (1 + rentabilidadeLiquida) ** (MESES_ANO / tempo) - 1
  const rentabilidadeLiquidaAoMes = (1 + rentabilidadeLiquida) ** (1 / tempo) - 1

  const valorFinalLiquido = totalInvestido * (1 + rentabilidadeLiquida)
  const valorFinalReal = valorFinalLiquido / (1 + ipcaAoDia) ** (tempo * DIAS_UTEIS_MENSAL)

  const obj = {
    liquido: Number((100 * rentabilidadeLiquida).toFixed(2)),
    liquido12: Number((100 * rentabilidadeLiquidaAoAno).toFixed(2)),
    liquido13: Number((100 * rentabilidadeLiquidaAoMes).toFixed(2)),
    final: Number(valorFinalLiquido).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }),
    real: Number(valorFinalReal).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }),
  }
  return obj
}

export default investmentSimulator

import styled, { keyframes } from "styled-components"

const pulse = keyframes`
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-10px);
  }
`

export const ArrowDownAnimated = styled.img`
  animation: ${pulse} 1.5s linear infinite;
`

export const Button = styled.button`
  background: transparent;
  border: 0;
  outline: 0;
`

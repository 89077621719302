import React from "react"
import * as S from "./styled"

const Thumb = props => <S.Thumb {...props} />

const Track = (props, { index }) => <S.Track {...props} index={index} />

function Slider({ onChange, min, max, step, value }) {
  return (
    <S.Wrapper>
      <S.Slider
        value={value}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        defaultValue={0}
        renderTrack={Track}
        renderThumb={Thumb}
      />
    </S.Wrapper>
  )
}

export default Slider

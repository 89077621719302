import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import getImageByName from "../../helpers/getImageByName"
import Button from "../commons/Button"
import Typography from "../commons/Typography"
import * as S from "./styled"

const query = graphql`
  query MyQuery {
    allFile(filter: { relativePath: { regex: "/ceo-avatar|ceo-sign/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fixed {
              base64
              width
              height
              src
              srcSet
            }
          }
          name
        }
      }
    }
  }
`

const CEOTestmony = () => {
  const images = useStaticQuery(query)
  const [ceoAvatar] = getImageByName(images.allFile.edges, "ceo-avatar")

  return (
    <>
      <S.CEOTestmony>
        <S.CEOInfoWrapper>
          <Img
            fixed={ceoAvatar.node.childImageSharp.fixed}
            alt="Ceo Juliano Custódio de braços cruzados sorrindo"
          />
          <Typography type="p" weight="bold" size="14px">
            Pode contar comigo!
          </Typography>
          <Typography type="p" weight="300" size="14px" margin="8px 0">
            Juliano Custodio
          </Typography>
          <Typography type="p" weight="300" size="14px">
            CEO da EQI Investimentos
          </Typography>
          <Button onClick={() => scrollTo("#calculator")}>
            <Typography type="p" size="14px" color="white">
              QUERO SABER MAIS
            </Typography>
          </Button>
        </S.CEOInfoWrapper>
        <S.InvestmentInfosWEB>
          <div>
            <Typography
              type="h1"
              size="48px"
              lineHeight="60px"
              weight="bold"
              color="white"
            >
              Quais investimentos rendem mais?
            </Typography>
            <Typography
              type="h2"
              size="48px"
              lineHeight="60px"
              weight="400"
              color="white"
            >
              Como comparar investimentos?
            </Typography>
            <Typography
              type="p"
              lineHeight="34px"
              size="20px"
              weight="400"
              margin="48px 0 0 0"
              color="white"
            >
              Essa foi a pergunta que mais ouvi durante os vários anos em que
              trabalho com investimentos.
            </Typography>
            <Typography
              type="p"
              lineHeight="34px"
              size="20px"
              weight="400"
              margin="32px 0"
              color="white"
            >
              E a resposta é simples: depende!
            </Typography>
            <Typography
              type="p"
              lineHeight="34px"
              size="20px"
              weight="400"
              margin="32px 0"
              color="white"
            >
              Não existem fórmulas mágicas... Para atingir os resultados, você
              precisa conhecer seus objetivos e quais tipos de investimentos
              combinam mais com o seu perfil.
            </Typography>
            <Typography
              type="p"
              lineHeight="34px"
              size="20px"
              weight="400"
              color="white"
            >
              Faça a simulação, <b>receba o diagnóstico</b> do seu atual momento
              e torne-se um investidor de sucesso.
            </Typography>
            <Button onClick={() => scrollTo("#calculator")}>
              <Typography type="p" size="16px" color="white">
                QUERO SABER MAIS
              </Typography>
            </Button>
          </div>
          <div>
            <S.CEOInfoDetails>
              <Typography type="p" weight="bold" size="17px" color="white">
                Pode contar comigo!
              </Typography>
              <Typography
                type="p"
                weight="300"
                size="16px"
                color="white"
                margin="8px 0"
              >
                Juliano Custodio
              </Typography>
              <Typography type="p" weight="600" size="18px" color="white">
                CEO da EQI Investimentos
              </Typography>
            </S.CEOInfoDetails>
          </div>
        </S.InvestmentInfosWEB>
      </S.CEOTestmony>
    </>
  )
}

export default CEOTestmony

import React from "react"
import Select, { components } from "react-select"
import * as S from "./styled"
import DropDownIndicator from "../../../../static/icon-dropdown.svg"
import { defaultTheme } from "../../../styles/theme"

const {
  colors: { mediumGray },
  typography: { placeholder },
} = defaultTheme

const customStyles = {
  control(provided) {
    return {
      ...provided,
      height: "40px",
      border: "1px solid #f0f0f0",
      background: mediumGray,
    }
  },
  placeholder(provided) {
    return {
      ...provided,
      color: placeholder,
    }
  },
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DropDownIndicator} alt="" />
    </components.DropdownIndicator>
  )
}

function CustomSelect({
  placeholder,
  label,
  options,
  selected,
  onChange,
  isDisabled,
  name,
}) {
  return (
    <S.SelectGroup>
      <S.Label>{label}</S.Label>
      <Select
        name={name}
        isDisabled={isDisabled}
        onChange={onChange}
        defaultValue={selected}
        placeholder={placeholder}
        styles={customStyles}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        options={options}
      />
    </S.SelectGroup>
  )
}

export default CustomSelect

import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import DoubleArrow from "../../../static/double-arrow-down.svg"
import Typography from "../commons/Typography"
import Header from "../Header"
import ArrowDown from "../commons/ArrowDown"

function HeroContent() {
  const {
    typography: { light, sizes },
  } = useTheme()
  const { header } = sizes

  return (
    <S.HeroContent>
      <Header />
      <S.Content>
        <S.LeftContainer>
          <Typography
            size={header}
            type="h1"
            color={light}
            weight={600}
            margin="20px 0 0 0"
          >
            Simulador de investimentos personalizado para você.
          </Typography>
          <Typography type="p" color={light} lineHeight={2} size="16px">
            Quanto seu dinheiro pode render no período?{" "}
            <b>Qual a melhor aplicação para seu perfil?</b> Faça a Simulação de
            Investimentos gratuita e receba todas informações.
          </Typography>
          <S.ArrowContainer>
            <ArrowDown onClick={() => scrollTo("#calculator")} />
          </S.ArrowContainer>
          <S.ArrowContainerWeb>
            <ArrowDown
              onClick={() => scrollTo("#calculator")}
              image={DoubleArrow}
            />
          </S.ArrowContainerWeb>
        </S.LeftContainer>
        <S.RightContainer />
      </S.Content>
    </S.HeroContent>
  )
}

export default HeroContent

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"

export const GeneralContainer = styled.div`
  width: 100%;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

export const InvestmentInfos = styled.div`
  ${DefaultSafeAreaPadding}
  height: 100%;
`

export const HeaderTextBlock = styled.div`
  ${DefaultSafeAreaPadding}
  width: 100%;
  margin-top: 30px;
`

export const BlueCircleHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

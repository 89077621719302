import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import Typography from "../commons/Typography"
import Header from "../Header"
import Button from "../commons/Button"

function HeroContentFunds() {
  const {
    colors: { lightOrange, dark },
    typography: { light, sizes },
  } = useTheme()
  const { header } = sizes

  return (
    <S.HeroContent>
      <Header />
      <S.Content>
        <Typography
          size={header}
          type="h1"
          lineHeight="36px"
          color={light}
          weight={600}
          margin="20px 0 0 0"
        >
          Mais controle e rentabilidade: Planilha Fundamentalista de Ações
        </Typography>
        <Typography type="p" color={light} lineHeight="26px" size="14px">
          Quero fazer simulações, entender quais os melhores investimentos e
          como melhorar a rentabilidade das ações? Tome decisões assertivas com
          base no seu balanço e nos dados de mercado. Baixe agora a Planilha de
          Indicadores de empresas listadas na Bolsa.
        </Typography>
        <Button onClick={() => scrollTo("#form")} bgcolor={lightOrange}>
          <Typography type="p" color={dark} weight={600} size="14px">
            QUERO RECEBER A PLANILHA
          </Typography>
        </Button>
      </S.Content>
    </S.HeroContent>
  )
}

export default HeroContentFunds

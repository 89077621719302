import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../../styles/styled-defaults"
import { customMedia } from "../../../styles/customMedia"

const PinesContainer = styled.div`
  ${DefaultSafeAreaPadding}
  height: calc(100% - 48px);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;

  ${customMedia.greaterThan("smallDesktop")`
    background-position: 0 -30%;
  `}
`

export default PinesContainer

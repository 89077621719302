import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import Button from "../commons/Button"
import Typography from "../commons/Typography"

function SpreadsheetBenefits() {
  const {
    typography: { dark },
  } = useTheme()

  return (
    <S.SpreadsheetBenefits>
      <S.Content>
        <S.HeaderWrapper>
          <Typography
            type="h1"
            size="24px"
            margin="0 0 24px 0"
            lineHeight="34px"
            textAlign="center"
          >
            Planilha de Ativos
          </Typography>
          <Typography type="p" size="14px" lineHeight="24px">
            Para ter sucesso nos seus investimentos é preciso persistência e
            olhar crítico.
          </Typography>
          <Typography type="p" size="14px" lineHeight="24px">
            Eu quero te ajudar a chegar lá! Por isso, montamos uma Planilha de
            Ativos para que você consiga tomar as melhores decisões.
          </Typography>
          <Button onClick={() => scrollTo("#form")}>
            <Typography type="p" color={dark} size="20px" weight={600}>
              QUERO RECEBER A PLANILHA
            </Typography>
          </Button>
        </S.HeaderWrapper>
        <S.BenefitsContainer>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Dados
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Tenha dados para tomar decisões certeiras.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Praticidade e precisão
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Acesse de onde estiver e quando preferir.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Planilha
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Baixe a Planilha de Ativos.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Balanço Financeiro
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Preencha com seu balanço financeiro.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
        </S.BenefitsContainer>
      </S.Content>
      <Button onClick={() => scrollTo("#form")}>
        <Typography type="p" color={dark} size="14px" weight={600}>
          QUERO RECEBER A PLANILHA
        </Typography>
      </Button>
    </S.SpreadsheetBenefits>
  )
}

export default SpreadsheetBenefits

import React from "react"
import * as S from "./styled"
import Typography from "../commons/Typography"

function InvestmentInfos() {
  return (
    <S.GeneralContainer>
      <S.BlueCircleHeader>
        <S.HeaderTextBlock>
          <Typography margin="0 0 5px 0" type="p" size="24px" weight="bold">
            Quais investimentos rendem mais?
          </Typography>
          <Typography margin="0 0 15px 0" type="p" size="24px" weight="300">
            Como comparar investimentos?
          </Typography>
        </S.HeaderTextBlock>
      </S.BlueCircleHeader>
      <S.InvestmentInfos>
        <Typography
          margin="0 0 15px 0"
          type="p"
          lineHeight="25px"
          size="14px"
          weight="300"
        >
          Essa foi a pergunta que mais ouvi durante os vários anos em que
          trabalho com investimentos.
        </Typography>
        <Typography
          margin="0 0 15px 0"
          type="p"
          lineHeight="25px"
          size="14px"
          weight="300"
        >
          E a resposta é simples: depende!
        </Typography>
        <Typography
          margin="0 0 15px 0"
          type="p"
          lineHeight="25px"
          size="14px"
          weight="300"
        >
          Não existem fórmulas mágicas... Para atingir os resultados, você
          precisa conhecer seus objetivos e quais tipos de investimentos
          combinam mais com o seu perfil.
        </Typography>
        <Typography
          margin="0 0 15px 0"
          type="p"
          lineHeight="25px"
          size="14px"
          weight="300"
        >
          Faça a simulação, <b>receba o diagnóstico</b> do seu atual momento e
          torne-se um investidor de sucesso.
        </Typography>
      </S.InvestmentInfos>
    </S.GeneralContainer>
  )
}

export default InvestmentInfos

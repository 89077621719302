import React from "react"
import { useTheme } from "styled-components"
import Typography from "../commons/Typography"
import Button from "../commons/Button"
import { Header } from ".."
import * as S from "./styled"

function HeroContentThankYouSpreadsheet() {
  const {
    colors: { dark, lightOrange },
  } = useTheme()

  return (
    <S.Container>
      <Header />
      <S.Content>
        <Typography
          type="h1"
          color={dark}
          size="30px"
          margin="8px 0 0 0"
          lineHeight="38px"
          weight="bold"
        >
          Obrigado pelo seu interesse na Planilha de Ativos!
        </Typography>
        <Typography
          type="p"
          color={dark}
          size="16px"
          margin="16px 0 0 0"
          lineHeight="24px"
        >
          Clique no botão abaixo para acessar a planilha.
        </Typography>
            <Button  bgcolor={lightOrange}>	
        <S.Anchor 
          target="_blank" 
          href="https://docs.google.com/spreadsheets/d/1feSww8-_w1aF2xkBChoSMY5Dbq-UQtZTDEBFmmbBckw/copy"
          rel="noreferrer"
          >
              <Typography type="p" color={dark} weight={600} size="14px">	
                QUERO RECEBER A PLANILHA	
              </Typography>	
        </S.Anchor>
            </Button>
      </S.Content>
      <S.OrangeSquare />
    </S.Container>
  )
}

export default HeroContentThankYouSpreadsheet

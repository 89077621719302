export const getAliquotaImpostoAPagar = (impostoRenda, meses) => {
  if (impostoRenda !== "paga") return 0

  if (meses <= 6) return 0.225

  if (meses <= 12) return 0.2

  if (meses <= 24) return 0.175

  return 0.15
}

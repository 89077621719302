import { useEffect, useState } from "react"

const useDailyValues = () => {
  const [dailyValues, setDailyValues] = useState({})

  useEffect(() => {
    // eslint-disable-next-line
    fetch(
      "https://eqi-tech-tools.azurewebsites.net/api/LpCalculatorIndicator?code=QHEOz0YHChe2jQyFSzX4mnsJYiDww2uA1iEq3ECRgwO0rbI/Coo8zA=="
    )
      .then(response => response.json())
      .then(setDailyValues)
  }, [])

  return dailyValues
}

export default useDailyValues

import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"
import { customMedia } from "../../styles/customMedia"

export const SpreadsheetBenefits = styled.div`
  ${DefaultSafeAreaPadding}
  background-color: ${({ theme }) => theme.colors.dark};
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;
  overflow-x: hidden;

  & > button {
    margin-top: 20px;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    padding: 40px;

    & > button {
      display: none;
    }
  `}
`

export const HeaderWrapper = styled.div`
  padding-bottom: 20px;
  position: relative;

  & > button {
    display: none;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    & > h1 {
      font-size: 50px;
      line-height: 55px;
      text-align: center;
      margin-bottom: 30px;
    }
    & > p {
      font-size: 24px;
      text-align: center;
      margin-bottom: 32px;
      line-height: 24px;
    }
    & > button {
      margin-top: 24px;
      width: 100%;
      display: inline-block;
    }
  `}
  ${customMedia.greaterThan("largeDesktop")`
    & > h1 {
      font-size: 60px;
      line-height: 65px;
      text-align: center;
      margin-bottom: 40px;
    }
    & > p {
      font-size: 24px;
      text-align: center;
      margin-bottom: 32px;
      line-height: 34px;
    }
  `}
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${customMedia.greaterThan("mediumDesktop")`
    & > p {
      font-size: 14px;
    }
  `}
`

export const BenefitsContainer = styled.div`
  ${customMedia.greaterThan("mediumDesktop")`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 700px;
  `}
`

export const Benefits = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 240px;
  color: ${({ theme }) => theme.colors.dark};
  padding: 30px;

  & + div {
    margin-top: 30px;
  }

  & > button {
    color: ${({ theme }) => theme.colors.neon};
    margin-top: 24px;
    background-color: transparent;
    border: none;
    outline: none;

    & > span {
      margin-left: 20px;
    }
  }

  ${customMedia.greaterThan("mediumDesktop")`
    width: 300px;
    height: 300px;
    margin-right: 20px;
    margin-bottom: 20px;

    & + div {
      margin-top: unset;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  ${customMedia.greaterThan("mediumDesktop")`
    width: 100%;
    flex-direction: row;
    justify-content: center;

     > div:first-child {
      width: 40%;
      margin-right: 100px;
    }
  `}
`

import React from "react"
import { useTheme } from "styled-components"
import * as S from "./styled"
import Typography from "../commons/Typography"

function TheHabitOfInvesting() {
  const {
    typography: { dark },
  } = useTheme()

  return (
    <S.TheHabitOfInvesting>
      <S.BlueSquare />
      <S.OrangeSquare />
      <Typography
        type="h1"
        size="24px"
        margin="0 0 24px 0"
        lineHeight="34px"
        color={dark}
        textAlign="center"
      >
        Acompanhe agora
      </Typography>
      <Typography
        type="p"
        size="14px"
        lineHeight="24px"
        color={dark}
        textAlign="center"
      >
        Preparei um vídeo especial sobre <b>&quot;O hábito de investir&quot;</b>
        . Acredito que você vai gostar bastante do conteúdo. Depois me conte o
        que achou!
      </Typography>
      <iframe
        width="100%"
        height="180px"
        src="https://www.youtube.com/embed/hrkNolsaZsI"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </S.TheHabitOfInvesting>
  )
}

export default TheHabitOfInvesting

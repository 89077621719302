import styled from "styled-components"

export const PercentWrapper = styled.div`
  margin-top: 10px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

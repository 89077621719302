import styled, { css } from "styled-components"

const Input = css`
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.info};
  border-radius: 50%;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${Input}

  /* On mouse-over, add a grey background color */
  &:hover input ~ ${Checkmark} {
    background-color: white;
  }
  /* When the radio button is checked, add a blue background */
  & input:checked ~ ${Checkmark} {
    background-color: ${({ theme }) => theme.colors.info};
  }
  /* Show the indicator (dot/circle) when checked */
  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  & ${Checkmark}:after {
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
`

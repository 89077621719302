import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import Button from "../commons/Button"
import Typography from "../commons/Typography"

const query = graphql`
  query {
    file(relativePath: { eq: "analyst.png" }) {
      childImageSharp {
        fluid(maxWidth: 847) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function FundsInformations() {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  const {
    colors: { lightOrange },
    typography: { dark },
  } = useTheme()

  return (
    <S.FundsInformations>
      <S.Content>
        <S.HeaderWrapper>
          <Typography
            type="h1"
            size="24px"
            margin="0 0 24px 0"
            lineHeight="34px"
            color={dark}
          >
            O que são Fundos Imobiliários?
          </Typography>
          <Typography type="p" size="14px" lineHeight="24px" color={dark}>
            Um fundo imobiliário funciona como um grupo de investidores que se
            unem para comprar ou montar investimentos em conjunto. No caso dos
            fundos imobiliários, investir em um imóvel.
            <b> Existem vários tipos de imóveis para investir, veja só!</b>
          </Typography>
          <Button onClick={() => scrollTo("#form")} bgcolor={lightOrange}>
            <Typography weight="600" type="p" color={dark} size="14px">
              Quero saber mais
            </Typography>
          </Button>
        </S.HeaderWrapper>
        <S.FluidImageContainer>
          <S.GreenSquare />
          <S.BlueSquare />
          <Img
            fluid={fluid}
            alt="Ceo Juliano Custódio de braços cruzados sorrindo"
          />
        </S.FluidImageContainer>
      </S.Content>
      <Button onClick={() => scrollTo("#form")} bgcolor={lightOrange}>
        <Typography type="p" color={dark} size="14px">
          Quero saber mais
        </Typography>
      </Button>
    </S.FundsInformations>
  )
}

export default FundsInformations

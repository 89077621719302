import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"
import { customMedia } from "../../styles/customMedia"

export const Footer = styled.div`
  ${DefaultSafeAreaPadding}
  height: 40%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px;

  img:first-child {
    width: 100%;
    max-height: 55px;
  }

  img:last-child {
    width: 100%;
    max-height: 55px;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    img:first-child { 
      width: unset;
      max-height: 55px;
    }
    
    img:last-child { 
      width: unset;
      max-height: 55px;
    }
  `}
`

export const VerticalDivider = styled.div`
  height: 55px;
  width: 2px;
  background-color: black;
  margin: 0 16px;
  ${customMedia.greaterThan("mediumDesktop")`
    margin: 0 40px;
  `}
`

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  order: 1;
  position: relative;

  & > form {
    & > button {
      margin-top: 16px;
    }
  }
  ${customMedia.greaterThan("smallDesktop")`
    width: 50%;
    padding: 24px;
    justify-content: space-between;

    & > h1 {
      font-size: 36px;
      line-height: 1.2;
      margin-top: 0;
    }

    & > p {
      font-size: 24px;
      line-height: 1.2;
    }

    & > form {
      & > p {
        font-size: 20px;
      }
      & > button {
        font-size: 18px;
      }
    }
  `}

  ${customMedia.greaterThan("largeDesktop")`
    & > h1 {
      font-size: 40px;
    }

    & > p {
      font-size: 28px;
    }

    & > form {
      & > p {
        font-size: 24px;
      }
      & > button {
        font-size: 18px;
      }
    }
  `}
`

export const BlackSquare = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

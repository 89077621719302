import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "styled-components"
import Img from "gatsby-image"
import Typography from "../commons/Typography"
import * as S from "./styled"

const query = graphql`
  query {
    file(relativePath: { eq: "ceo-juliano-braco-cruzado.png" }) {
      childImageSharp {
        fluid(maxWidth: 847) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function CEOContactSpreadsheet() {
  const {
    typography: { dark },
  } = useTheme()

  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <S.CEOContactSpreadsheet>
      <S.FluidImageContainer>
        <S.GreenSquare />
        <S.BlueSquare />
        <Img
          fluid={fluid}
          alt="Ceo Juliano Custódio de braços cruzados sorrindo"
        />
      </S.FluidImageContainer>
      <S.CEOLegend>
        <Typography type="h1" size="24px" color={dark} weight="700">
          Conte comigo!
        </Typography>
        <Typography
          type="p"
          size="16px"
          color={dark}
          weight="400"
          margin="30px 0"
        >
          Para não perder nenhum conteúdo, cadastre o e-mail{" "}
          <strong>contato@euqueroinvestir.com</strong> em seus contatos
        </Typography>
        <S.Divider />
        <Typography
          type="p"
          size="20px"
          color={dark}
          margin="8px 0"
          weight="700"
        >
          Juliano Custodio
        </Typography>
        <Typography type="p" size="16px" color={dark}>
          CEO da EQI Investimentos, Assessor de Investimentos, Palestrante e
          Escritor
        </Typography>
      </S.CEOLegend>
      <S.CEOLegendWeb>
        <Typography type="h1" size="56px" color={dark} weight="700">
          Conte comigo!
        </Typography>
        <Typography
          type="p"
          size="26px"
          color={dark}
          lineHeight={1.5}
          weight="400"
          margin="30px 0"
        >
          Para não perder nenhum conteúdo, cadastre o e-mail{" "}
          <strong>contato@euqueroinvestir.com</strong> em seus contatos
        </Typography>
        <div>
          <S.Divider />
          <Typography
            type="p"
            size="18px"
            color={dark}
            weight="700"
            margin="12px 0"
          >
            Juliano Custodio
          </Typography>
          <Typography type="p" size="16px" color={dark}>
            CEO da EQI Investimentos, Assessor de Investimentos, Palestrante e
            Escritor
          </Typography>
        </div>
      </S.CEOLegendWeb>
    </S.CEOContactSpreadsheet>
  )
}

export default CEOContactSpreadsheet

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"
import CeoBackground from "../../../static/ceo-juliano-background.png"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"

export const CEOTestmony = styled.div`
  ${DefaultSafeAreaPadding}
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    height: 1080px;
    padding: 20px;
    background: url(${CeoBackground}) no-repeat;
    background-size: cover;
    background-position: center;
  `}
`

export const CEOInfoWrapper = styled.div`
  padding: 0 0 24px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  & > button {
    width: 100%;
    margin-top: 24px;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

export const InvestmentInfosWEB = styled.div`
  display: none;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 15%;
  gap: 70px;

  & > div:nth-child(1) {
    flex: 1;

    & > button {
      margin-top: 50px;
    }

    ${customMedia.between("mediumDesktop", "largeDesktop")`
      & > h1, h2 {
        font-size: 42px;
        line-height: 1.1;
      }
      & > p {
        margin-top: 18px;
        line-height: 1.2;
      }
    `}
    ${customMedia.between("largeDesktop", "hugeDesktop")`
      & > h1, h2 {
        font-size: 48px;
        line-height: 1.3;
      }
      & > p {
        margin-top: 20px;
        line-height: 1.4;
      }
    `}
  }

  & > div:nth-child(2) {
    flex: 1;
    align-self: flex-end;
    display: flex;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > img {
        margin-bottom: 16px;
      }
    }
  }

  ${customMedia.greaterThan("mediumDesktop")`
    display: flex;
  `}
`

export const CEOInfoDetails = styled.div`
  display: none;
  width: 400px;
  height: 330px;
  position: absolute;
  bottom: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neon};

  ${customMedia.greaterThan("mediumDesktop")`
    display: flex;
  `}
`

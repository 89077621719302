import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${customMedia.greaterThan("smallDesktop")`
    display: none;
    padding: 0 20%;
  `}
`

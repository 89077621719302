export const investmentOptions = [
  { value: "CDB", label: "CDB" },
  { value: "LCI", label: "LCI" },
  { value: "LCA", label: "LCA" },
  { value: "LC", label: "LC" },
  { value: "Tesouro", label: "Tesouro" },
  { value: "Fundos", label: "Fundos" },
  { value: "CRI", label: "CRI" },
  { value: "CRA", label: "CRA" },
  { value: "DEBENTURES", label: "Debêntures" },
  { value: "DEBENTURES_INCENT", label: "Debêntures Incent." },
  { value: "POUPANCA", label: "Poupança" },
]

export const investmentTermOptions = [
  { value: "3", label: "3 meses (90 dias)" },
  { value: "6", label: "6 meses (180 dias)" },
  { value: "9", label: "9 meses (270 dias)" },
  { value: "12", label: "12 meses (360 dias)" },
  { value: "18", label: "18 meses (540 dias)" },
  { value: "24", label: "24 meses (720 dias) [2 anos]" },
  { value: "30", label: "30 meses (900 dias)" },
  { value: "36", label: "36 meses (1080 dias) [3 anos]" },
  { value: "48", label: "48 meses (1440 dias) [4 anos]" },
  { value: "60", label: "60 meses (1800 dias) [5 anos]" },
  { value: "72", label: "72 meses [6 anos]" },
  { value: "84", label: "84 meses [7 anos]" },
  { value: "96", label: "96 meses [8 anos]" },
  { value: "108", label: "108 meses [9 anos]" },
  { value: "120", label: "120 meses [10 anos]" },
  { value: "180", label: "180 meses [15 anos]" },
  { value: "240", label: "240 meses [20 anos]" },
  { value: "300", label: "300 meses [25 anos]" },
  { value: "360", label: "360 meses [30 anos]" },
  { value: "420", label: "420 meses [35 anos]" },
  { value: "480", label: "480 meses [40 anos]" },
]

export const prePosOptions = [
  { value: "pre", label: "Pré" },
  { value: "pos", label: "Pós" },
  { value: "ipca", label: "IPCA+" },
]

export const investorRangeOptions = [
  {
    value: "Não tenho capital para investir",
    label: "Não tenho capital para investir",
  },
  { value: "Até R$ 30 mil", label: "Até R$ 30 mil" },
  { value: "De R$ 30 mil a R$ 60 mil", label: "De R$ 30 mil a R$ 60 mil" },
  { value: "De R$ 60 mil a R$ 100 mil", label: "De R$ 60 mil a R$ 100 mil" },
  { value: "De R$ 100 mil a R$ 300 mil", label: "De R$ 100 mil a R$ 300 mil" },
  { value: "De R$ 300 mil a R$ 500 mil", label: "De R$ 300 mil a R$ 500 mil" },
  {
    value: "De R$ 500 mil a R$ 1 milhão",
    label: "De R$ 500 mil a R$ 1 milhão",
  },
  {
    value: "De R$ 1 milhão a R$ 5 milhões",
    label: "De R$ 1 milhão a R$ 5 milhões",
  },
  { value: "Acima de R$ 5 milhões", label: "Acima de R$ 5 milhões" },
]

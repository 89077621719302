import styled from "styled-components"

export const StyledButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  background: ${({ theme }) => theme.colors.neon};
  ${({ bgcolor }) => bgcolor && `background-color: ${bgcolor}`};
  ${({ color }) => color && `color: ${color}`};
  outline: none;
`

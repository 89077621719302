import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
  order: 2;

  ${customMedia.greaterThan("smallDesktop")`
    order: 3;
  `}
`

export const StyledAnchor = styled.a`
  display: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blueLink};
  text-align: center;
  margin-top: 8px;

  ${customMedia.greaterThan("smallDesktop")`
    display: block;
  `}
`

const getCurrencyConfig = () => {
  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  }

  return currencyConfig
}

export default getCurrencyConfig

export const getReturnUrlGatsby = (urlReturn) => {
  return typeof window !== "undefined"
  ? `${window.location.origin}/${urlReturn}`
  : null
};

export const getFullUrl = () =>
  typeof window !== 'undefined' ? window.location.href : '';

export const getUrlParamByName = name => {
  const url = getFullUrl;
  const firstRegex = name.replace(/[[\]]/g, '\\$&');
  const secondRegex = new RegExp(`[?&]${firstRegex}(=([^&#]*)|&|#|$)`);
  const results = secondRegex.exec(url);

  if (!results) return null;
  if (!results[2]) return ' ';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

import React, { useState, memo, useEffect } from "react"
import { useTheme } from "styled-components"
import getSelectedValueReactSelect from "../../../helpers/getSelectedValueReactSelect"
import {
  investmentOptions,
  prePosOptions,
} from "../../../helpers/getSelectOptions"
import Col from "../Col"
import CustomSelect from "../CustomSelect"
import Input from "../Input"
import Row from "../Row"
import Typography from "../Typography"
import * as S from "./styled"

function InvestmentForm({ handleChange, title }) {
  const [investmentFormValues, setInvestmentFormValues] = useState({
    ir: "LCI",
    tipo: "",
  })
  const { ir, tipo } = investmentFormValues
  const isPoupanca = ir === "POUPANCA"
  const showCDI = !(tipo === "pre") && !(tipo === "ipca") && !isPoupanca
  const showIPCA = tipo === "ipca" && !isPoupanca

  const {
    typography: { dark, gray },
  } = useTheme()

  useEffect(() => {
    if (investmentFormValues) handleChange(investmentFormValues)
  }, [investmentFormValues])

  return (
    <Col col={12} md={4}>
      <Typography
        type="p"
        size="14px"
        weight="bold"
        color={dark}
        lineHeight="2"
      >
        {title}
      </Typography>
      <CustomSelect
        options={investmentOptions}
        selected={getSelectedValueReactSelect(investmentOptions, "LCI")}
        onChange={({ value }) =>
          setInvestmentFormValues(old => ({ ...old, ir: value }))
        }
        placeholder="LCI"
      />
      <Row gap={8}>
        <Col col={4}>
          <CustomSelect
            options={prePosOptions}
            placeholder="Pós"
            isDisabled={isPoupanca}
            selected={
              isPoupanca && getSelectedValueReactSelect(prePosOptions, "Pré")
            }
            onChange={({ value }) =>
              setInvestmentFormValues(old => ({ ...old, tipo: value }))
            }
          />
        </Col>
        <Col col={4}>
          <Input
            onChange={({ currentTarget: { value } }) =>
              setInvestmentFormValues(old => ({ ...old, percdi: value }))
            }
            placeholder="1,40"
          />
        </Col>
        <Col col={4}>
          <S.PercentWrapper>
            <Typography textAlign="start" type="p" size="15px" color={gray}>
              % {showCDI && "do CDI"}
              {showIPCA && "+ IPCA"}
            </Typography>
          </S.PercentWrapper>
        </Col>
      </Row>
    </Col>
  )
}

export default memo(InvestmentForm)

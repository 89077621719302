import styled from "styled-components"

export const SelectGroup = styled.div`
  margin: 10px 0;
`

export const Label = styled.label`
  padding: 0 15px;
  margin-bottom: 10px;
  font-size: 14px;
  display: block;
  color: ${({ theme }) => theme.typography.dark};
`

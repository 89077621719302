import styled from "styled-components"

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ gap }) =>
    gap &&
    `
    gap: ${gap}px;
  `}
  ${({ mt }) =>
    mt &&
    `
    margin-top: ${mt}px;
  `}
  ${({ alignItems }) =>
    alignItems &&
    `
    align-items: ${alignItems};
  `}
`

export default Row

import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"
import { customMedia } from "../../styles/customMedia"

export const FundsForm = styled.form`
  ${DefaultSafeAreaPadding}
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    padding-left: 22%;
    padding-right: 22%;

    label {
      font-size: 14px;
    }
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    padding-left: 25%;
    padding-right: 25%;
  `}
`

export const HeaderWrapper = styled.div`
  padding-bottom: 20px;

  ${customMedia.greaterThan("mediumDesktop")`
    & > p {
      font-size: 32px;
      text-align: center;
      margin-bottom: 32px;
      line-height: 42px;
    }
  `}
`

export const RadioGroup = styled.div`
  padding: 20px 15px 0;

  ${customMedia.greaterThan("mediumDesktop")`
    padding: 0;
    p {
      padding: 0 15px;
      font-size: 14px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  `}
`

export const PrivacyWrapper = styled.div`
  direction: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    p {
      font-size: 12px;
    }
  `}
  ${customMedia.greaterThan("hugeDesktop")`
    p {
      font-size: 14px;
    }
  `}
`

export const TypographyWrapper = styled.div`
  margin-left: 20px;
`

export const DiagnosisButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;

  ${customMedia.greaterThan("mediumDesktop")`
  margin: 50px 0;
    button {
      width: 600px;
    }
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 105px;
  height: 105px;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
  `}
`

export const BlackSquare = styled.div`
  position: absolute;
  display: none;
  top: 150px;
  right: 0px;
  width: 135px;
  height: 135px;
  background-color: ${({ theme }) => theme.colors.dark};
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
  `}
`

import React from "react"
import { useTheme } from "styled-components"
import Typography from "../commons/Typography"
import { Header } from ".."
import * as S from "./styled"

function Congratulations() {
  const {
    colors: { dark },
  } = useTheme()

  return (
    <S.Container>
      <Header />
      <S.BlackSquare />
      <S.Content>
        <Typography
          type="h1"
          color={dark}
          size="30px"
          margin="16px 0 0 0"
          lineHeight="38px"
          weight="bold"
        >
          Parabéns, você deu o primeiro passo!
        </Typography>
        <Typography
          type="p"
          color={dark}
          size="16px"
          margin="24px 0 0 0"
          lineHeight="24px"
          weight={400}
        >
          A <b>Simulação de Investimentos</b> será enviada para o e-mail
          cadastrado em instantes.
        </Typography>
        <Typography
          type="p"
          color={dark}
          size="16px"
          lineHeight="24px"
          weight={400}
        >
          Preciso confessar, eu fico muito entusiasmado em perceber que mais
          pessoas estão interessadas em investir, sabia? Vamos levar mais{" "}
          <b>independência financeira e conhecimento</b> juntos!
        </Typography>
      </S.Content>
      <S.OrangeSquare />
    </S.Container>
  )
}

export default Congratulations

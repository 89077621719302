import IntlCurrencyInput from "react-intl-currency-input"
import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"

export const TitleWrapper = styled.div`
  width: 100%;
  margin: 30px 0;

  ${customMedia.greaterThan("mediumDesktop")`
    width: 100%;

    & > h1 {
      text-align: center;
      font-size: 32px;
    }
  `}
`

export const InvestmentCalculatorContainer = styled.div`
  ${DefaultSafeAreaPadding}
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    padding-left: 22%;
    padding-right: 22%;
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    padding-left: 25%;
    padding-right: 25%;
  `}
`

export const InvestmentCalculatorContent = styled(
  InvestmentCalculatorContainer
)`
  ${customMedia.greaterThan("mediumDesktop")`
    margin-top: 40px;

    & > label {
      font-size: 22px;
    }
    
    & > p {
      font-size: 22px;
    }
  `}
`

export const InvestmentCalculatorHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${customMedia.greaterThan("mediumDesktop")`
    & > img {
      display: none;
    }

    & + p {
      display: none;
    }
  `}
`

export const OrangeSquare = styled.div`
  display: none;
  width: 130px;
  height: 170px;
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.lightOrange};
  `}
`

export const BlackSquare = styled.div`
  display: none;
  width: 95px;
  height: 95px;
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
    position: absolute;
    top: 200px;
    left: 10%;
    background-color: ${({ theme }) => theme.colors.dark};
  `}
`

export const BlueCircleDivider = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;

  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

export const BlueCircle = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  margin-right: -35px;
  background-color: ${({ theme }) => theme.colors.info};
`

export const UnitWrapper = styled.div`
  padding: 0 10px 10px 0;
`

export const SliderValueShower = styled.div`
  width: 100%;
  height: 65px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.mediumGray};
  margin: 20px 0;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
`

export const PercentWrapper = styled.div`
  margin-top: 10px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const InvisibleInvestmentInput = styled(IntlCurrencyInput)`
  border: none;
  background-color: ${({ theme }) => theme.colors.mediumGray};
  outline: none;
  font-size: 44px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.dark};
`

export const ContributionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${customMedia.greaterThan("mediumDesktop")`
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;

    & > div {
      flex: 1;
    }
  `}
`

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 16px;
  background: transparent;
  position: relative;

  ${customMedia.between("320px", "360px")`
    h1 {
      margin: 0px 0 0 0;
      font-size: 30px;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }
  `}

  ${customMedia.greaterThan("smallDesktop")`
    padding: 0 10%;

    h1 {
      margin-top: 0px;
      font-size: 58px;
      line-height: 1.2;
    }
    p {
      font-size: 34px;
      line-height: 1.2;
    }
  `}

  ${customMedia.greaterThan("mediumDesktop")`
    h1 {
      font-size: 50px;
      line-height: 1.2;
    }
    p {
      font-size: 24px;
      line-height: 1.2;
    }
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    h1 {
      font-size: 60px;
      line-height: 1.2;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;

  ${customMedia.greaterThan("smallDesktop")`
    justify-content: center;
    & > p {
      width: 50%;
    }
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  display: none;
  top: 200px;
  right: 0;
  width: 128px;
  height: 128px;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    display:block;
  `}
`

export const OrangeSquare = styled.div`
  position: absolute;
  display: none;
  bottom: -50px;
  left: 0;
  width: 105px;
  height: 105px;
  background-color: ${({ theme }) => theme.colors.lightOrange};
  ${customMedia.greaterThan("mediumDesktop")`
    display:block;
  `}
`

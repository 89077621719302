import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import DoubleArrow from "../../../static/double-arrow-down.svg"
import Typography from "../commons/Typography"
import Header from "../Header"
import ArrowDown from "../commons/ArrowDown"

function HeroContentFunds() {
  const {
    typography: { light, sizes },
  } = useTheme()
  const { header } = sizes

  return (
    <S.HeroContent>
      <Header />
      <S.Content>
        <Typography
          size={header}
          type="h1"
          lineHeight="36px"
          color={light}
          weight={600}
          margin="20px 0 0 0"
        >
          Seus pais
          <br />
          tinham razão
          <br />
          em comprar imóveis.
          <br />
          Você não.
        </Typography>
        <Typography type="p" color={light} lineHeight="26px" size="14px">
          Sabia que você pode investir em Fundo Imobiliário e ganhar dinheiro?
          Sem necessidade de comprar o imóvel e sem grandes investimentos!
          Entenda as melhores oportunidades e não perca dinheiro.
        </Typography>
        <S.ArrowContainer>
          <ArrowDown onClick={() => scrollTo("#form")} />
        </S.ArrowContainer>
        <S.ArrowContainerWeb>
          <ArrowDown onClick={() => scrollTo("#form")} image={DoubleArrow} />
        </S.ArrowContainerWeb>
      </S.Content>
    </S.HeroContent>
  )
}

export default HeroContentFunds

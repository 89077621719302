import React from "react"
import { useTheme } from "styled-components"
import Typography from "../commons/Typography"
import { Header } from ".."
import * as S from "./styled"

function HeroContentContactReceived() {
  const {
    colors: { dark },
  } = useTheme()

  return (
    <S.Container>
      <Header />
      <S.BlueSquare />
      <S.Content>
        <Typography
          type="h1"
          color={dark}
          size="30px"
          margin="8px 0 0 0"
          lineHeight="38px"
          weight="bold"
        >
          Pronto! Recebemos seu contato.
        </Typography>
        <Typography
          type="p"
          color={dark}
          size="16px"
          margin="16px 0 0 0"
          lineHeight="24px"
        >
          A <b>forma de investir mudou</b> e estamos felizes em te ajudar a
          escolher o melhor Fundo de Investimento Imobiliário!
        </Typography>
      </S.Content>
      <S.OrangeSquare />
    </S.Container>
  )
}

export default HeroContentContactReceived

const calculateFutureValue = (
  taxaJuros,
  prazoEmMeses,
  aportesMensais,
  valorInicial
) => {
  if (taxaJuros === 0)
    return (valorInicial + aportesMensais * prazoEmMeses).toFixed(2);

  const taxaTotal = (1 + taxaJuros) ** prazoEmMeses;
  return (
    taxaTotal * valorInicial +
    (taxaTotal * aportesMensais - aportesMensais) / taxaJuros
  ).toFixed(2);
}

export default calculateFutureValue

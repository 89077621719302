import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"
import PinesContainer from "../commons/PinesContainer"

export const SpreadsheetStatistics = styled(PinesContainer)`
  height: auto;
  align-items: center;
  padding: 40px 0;
  background-color: #f7f7f7;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    flex-direction: row;
    background-position: top;
    background-size: cover;
    padding: 80px 120px;
    flex-wrap: wrap;
    justify-content: center;

    & > p {
      margin-bottom: 40px;
      font-size: 44px;
    }

    & > button {
      margin-top: 40px;
      width: 392px;
    }
  `}
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${customMedia.greaterThan("mediumDesktop")`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`

// TODO: Essa formatação no P, infelizmente é um workaround, verificar no componente typography
export const InvestmentDataWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & p {
    margin: 0;
    text-align: center;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    & > p:nth-child(1) {
      font-size: 90px;
    }
    & > p:nth-child(2) {
      font-size: 24px;
    }
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    top: 200px;
    right: 200px;
    width: 55px;
    height: 55px;
  `}
`

export const NeonSquare = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neon};
  bottom: 0px;
  left: 0px;
  width: 26px;
  height: 26px;
  ${customMedia.greaterThan("mediumDesktop")`
    width: 100px;
    height: 100px;
  `}
`

const roundFloatingPoint = floatingPoint => {
  return Math.round(floatingPoint * 100) / 100
}

const calculateSavingsAmount = (savings = 0) => {
  const calculatedValue = parseFloat(
    100 * (Math.pow(savings / 100 + 1, 12) - 1)
  )
  return roundFloatingPoint(calculatedValue).toFixed(2).replace(".", ",")
}

export default calculateSavingsAmount

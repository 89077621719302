import styled, { css } from "styled-components"
import IntlCurrencyInput from "react-intl-currency-input"

const PlaceholderStyles = css``
const rightContentStyles = css`
  padding: 0 5px 0 15px;
`
const inputStyles = css`
  height: 100%;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    ${PlaceholderStyles}
  }
  &::-moz-placeholder {
    ${PlaceholderStyles}
  }
  &:-ms-input-placeholder {
    ${PlaceholderStyles}
  }
  &:-moz-placeholder {
    ${PlaceholderStyles}
  }
`

export const Input = styled.input`
  ${inputStyles}
  ${({ $hasRightContent }) => !$hasRightContent && "padding-left: 15px"}
`

export const CurrencyInput = styled(IntlCurrencyInput)`
  ${inputStyles}
  ${({ $hasRightContent }) => !$hasRightContent && "padding-left: 15px"}
`

export const InputGroup = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin: 10px 0;
  width: 100%;
  height: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.mediumGray};
  ${({ $hasRightContent }) => $hasRightContent && rightContentStyles}

  & > input {
    background-color: ${({ theme }) => theme.colors.mediumGray};

    &::placeholder {
      color: ${({ theme }) => theme.typography.placeholder};
    }
  }
`

export const Label = styled.label`
  padding: 0 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.typography.dark};
`

import React from "react"
import LogoEQI from "../../../static/eqi-orange-bg.svg"
import * as S from "./styled"

function Header() {
  return (
    <S.Container>
      <img src={LogoEQI} alt="Logo EQI" />
    </S.Container>
  )
}

export default Header

import React from "react"
import LogoEqi from "../../../static/eqi-orange.svg"
import * as S from "./styled"

const Footer = () => {
  return (
    <S.Footer>
      <img src={LogoEqi} alt="Logo com a escrita: Eu quero investir" />
    </S.Footer>
  )
}

export default Footer

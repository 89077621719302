import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;

  ${customMedia.greaterThan("smallDesktop")`
    padding: 0 15%;

    h1 {
      margin-top: 50px;
      font-size: 58px;
      line-height: 1.2;
    }
    p {
      font-size: 34px;
      line-height: 1.2;
    }
  `}

  ${customMedia.greaterThan("mediumDesktop")`
    h1 {
      font-size: 50px;
      line-height: 1.1;
    }
    p {
      font-size: 20px;
      line-height: 1.5;
    }
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    h1 {
      font-size: 60px;
      line-height: 1.2;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-evenly;
  height: 100vh;
  ${customMedia.greaterThan("mediumDesktop")`
    width: 50%;
  `}
`

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  ${customMedia.greaterThan("smallDesktop")`
    display: none;
  `}
`

export const ArrowContainerWeb = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 16px;
  ${customMedia.greaterThan("smallDesktop")`
    display: flex;
  `}
`

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const CEOProfile = styled.div`
  margin-top: 80px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${customMedia.greaterThan("mediumDesktop")`
    flex-direction: row;
    padding: 60px 0;
    align-items: flex-start;
    justify-content: space-between;
  `}
`

// Z-INDEX: 2, é mais alto que a foto, porém mais baixo do que o botão fixado
export const CEOLegend = styled.div`
  align-self: flex-start;
  padding: 20px;

  ${customMedia.between("768px", "1280px")`
    p {
      font-size: 18px;
    }
  `}

  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

export const Divider = styled.div`
  height: 3px;
  width: 80px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.dark};
`

export const CEOLegendWeb = styled.div`
  display: none;
  flex-direction: column;
  flex: 1;
  align-self: flex-start;
  margin-left: 100px;
  padding: 100px;

  & > div {
    margin-top: 40px;
  }
  ${customMedia.greaterThan("mediumDesktop")`
    display: flex;
  `}
`

export const BlackFakeBackground = styled.div`
  background: ${({ theme }) => theme.colors.dark};
  height: 200px;
  width: 100%;
  position: absolute;
  margin-top: 80px;

  ${customMedia.greaterThan("mediumDesktop")`
    display: none;
  `}
`

export const FluidImageContainer = styled.div`
  position: relative;
  width: 70%;
  ${customMedia.greaterThan("mediumDesktop")`
    width: 600px;
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  top: -26px;
  right: 0;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    top: 0;
    right: -115px;
    width: 115px;
    height: 115px;
  `}
`

export const GreenSquare = styled.div`
  position: absolute;
  right: 26px;
  top: 0;
  z-index: 1;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  ${customMedia.greaterThan("mediumDesktop")`
    top: 115px;
    right: 0;
    width: 115px;
    height: 115px;
  `}
`

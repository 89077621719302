import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"
import { customMedia } from "../../styles/customMedia"

export const FundsInformations = styled.div`
  ${DefaultSafeAreaPadding}
  background-color: ${({ theme }) => theme.colors.light};
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;

  ${customMedia.greaterThan("mediumDesktop")`
    button {
      display: none;
    }
  `}
`

export const HeaderWrapper = styled.div`
  padding-bottom: 20px;
  & > button {
    display: none;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > h1 {
      width: 50%;
      font-size: 40px;
      margin-bottom: 40px;
    }
    & > p {
      width: 50%;
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 40px;
    }
    & > button {
      width: 300px;
      display: inline-block;
    }
  `}
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;

  & > p {
    margin: 24px 0;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    margin-bottom: 24px;
    & > p {
      margin: 40px 0;
      font-size: 24px;
      line-height: 34px;
    }

    & > button {
      width: 600px;
    }
  `}
`

export const FluidImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  ${customMedia.greaterThan("mediumDesktop")`
    width: 600px;
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  top: -26px;
  right: 0;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    z-index: 1;
    top: unset;
    bottom: 115px;
    right: unset;
    left: 0px;
    width: 115px;
    height: 115px;
  `}
`

export const GreenSquare = styled.div`
  position: absolute;
  right: 26px;
  top: 0;
  z-index: 1;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  ${customMedia.greaterThan("mediumDesktop")`
    top: unset;
    bottom: 0;
    right: unset;
    left: -115px;
    width: 115px;
    height: 115px;
    flex-shrink: 0;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  ${customMedia.greaterThan("mediumDesktop")`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

     > div:first-child {
      width: 40%;
      margin-right: 100px;
    }
  `}
`

import { createGlobalStyle } from "styled-components"

const globalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  html,
  body,
  #gatsby-focus-wrapper,
  #___gatsby {
    height: 100%;
  }

  body {
    margin: 0px;
  }

  div[role="group"][tabindex] {
    height: 100%;
  }

  button {
    cursor: pointer;
  }
`

export default globalStyle

import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  order: 2;

  ${customMedia.greaterThan("smallDesktop")`
    width: 50%;
    padding: 24px;
  `}
`

export const AwardsContainer = styled.div`
  background-color: #ebeaef;
  border-radius: 8px;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    position: relative;
    gap: 30px;

    & > div {
      display: flex;
      align-items: center;
    }

    & + div::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 90%;
      left: 5%;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  ${customMedia.greaterThan("smallDesktop")`
    & > div {
      p:nth-child(1) {
        font-size: 42px;
      }
      p:nth-child(2) {
        font-size: 16px;
      }
      p:nth-child(3) {
        font-size: 22px;
      }
      p:nth-child(4) {
        width: auto;
        font-size: 16px;
      }
    }
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    & > div {
      p:nth-child(1) {
        font-size: 50px;
      }
      p:nth-child(2) {
        font-size: 18px;
      }
      p:nth-child(3) {
        font-size: 23px;
      }
      p:nth-child(4) {
        width: auto;
        font-size: 18px;
      }
    }
  `}
`

export const StyledAnchor = styled.a`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  margin: 16px 0;

  ${customMedia.greaterThan("smallDesktop")`
    display: none;
  `}
`

export const BlackSquare = styled.div`
  display: none;
  width: 115px;
  height: 115px;
  background-color: ${({ theme }) => theme.colors.dark};
  position: absolute;
  bottom: 150px;
  right: 0px;
  ${customMedia.greaterThan("mediumDesktop")`
    display: block
  `}
`

import styled, { css } from "styled-components"
import { customMedia } from "../../../styles/customMedia"

export default styled.div`
  ${({ col }) =>
    col === 12
      ? css`
          width: 100%;
        `
      : css`
          flex: ${({ col }) => 12 / col};
        `};

  ${customMedia.greaterThan("mediumDesktop")`
    ${({ md }) =>
      md === 12
        ? css`
            width: 100%;
          `
        : css`
            flex: ${({ md }) => 12 / md};
          `}
  `}

  ${customMedia.greaterThan("largeDesktop")`
    ${({ lg }) =>
      lg === 12
        ? css`
            width: 100%;
          `
        : css`
            flex: ${({ lg }) => 12 / lg};
          `}
  `}
`

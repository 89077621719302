import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import * as S from "./styled"
import Button from "../commons/Button"
import Typography from "../commons/Typography"

function FundsBenefits() {
  const {
    typography: { dark },
  } = useTheme()

  return (
    <S.FundsBenefits>
      <S.Content>
        <S.HeaderWrapper>
          <Typography
            type="h1"
            size="24px"
            margin="0 0 24px 0"
            lineHeight="34px"
          >
            <b>Vantagens de investir</b> no Fundo Imobiliário
          </Typography>
          <Typography type="p" size="14px" lineHeight="24px">
            Para investir no Fundo Imobiliário você só precisa de escolher os
            investimentos que combinam com o seu perfil.
          </Typography>
          <Typography type="p" size="14px" lineHeight="24px">
            E isso mesmo! Você recebe dinheiro pelo aluguel, venda ou
            arrendamento do imóvel sem precisar comprar, administrar o espaço e
            pagar taxas.
          </Typography>
          <Button onClick={() => scrollTo("#form")}>
            <Typography type="p" color={dark} size="24px" weight={600}>
              Fale com nosso assessor
            </Typography>
          </Button>
        </S.HeaderWrapper>
        <S.BenefitsContainer>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Sem Burocracia
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Sem preocupações, documentações extensas, IPTU, ITBI e sem custos
              de manutenção de imóveis.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Diversificação dos Investimentos
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              São vários imóveis disponíveis e você pode escolher de acordo com
              seu objetivo.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Sem Incidência no IR
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Pessoas físicas não precisam informar rendimentos no fundo.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
          <S.Benefits>
            <Typography
              type="p"
              size="22px"
              weight="700"
              lineHeight="24px"
              margin="0 0 20px 0"
              textAlign="center"
            >
              Liquidez
            </Typography>
            <Typography
              type="p"
              size="14px"
              lineHeight="20px"
              textAlign="center"
            >
              Cotas negociadas na Bolsa: sem riscos do imóvel não gerar receita.
            </Typography>
            <button onClick={() => scrollTo("#form")}>
              Saber mais <span>&rarr;</span>
            </button>
          </S.Benefits>
        </S.BenefitsContainer>
      </S.Content>
      <Button onClick={() => scrollTo("#form")}>
        <Typography type="p" color={dark} size="14px" weight={600}>
          Fale com nosso assessor
        </Typography>
      </Button>
    </S.FundsBenefits>
  )
}

export default FundsBenefits

import React from "react"
import * as S from "./styled"

function Typography({
  width,
  type,
  children,
  color,
  weight,
  lineHeight,
  size,
  margin,
  textAlign,
}) {
  return type === "p" ? (
    <S.Paragraph
      color={color}
      width={width}
      weight={weight}
      lineHeight={lineHeight}
      textAlign={textAlign}
      size={size}
      margin={margin}
    >
      {children}
    </S.Paragraph>
  ) : (
    <S.TextHeader
      type={type}
      color={color}
      width={width}
      weight={weight}
      lineHeight={lineHeight}
      textAlign={textAlign}
      size={size}
      margin={margin}
    >
      {children}
    </S.TextHeader>
  )
}

export default Typography

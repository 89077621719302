function getAliquotaImpostoPorInvestimento(product) {
  return {
    CDB: "paga",
    LCI: "isento",
    LCA: "isento",
    LC: "paga",
    Tesouro: "paga",
    Fundos: "paga",
    CRI: "isento",
    CRA: "isento",
    DEBENTURES: "paga",
    DEBENTURES_INCENT: "isento",
    POUPANCA: "isento",
  }[product]
}

export default getAliquotaImpostoPorInvestimento

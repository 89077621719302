import React, { useState, useEffect, useCallback } from "react"
import { useTheme } from "styled-components"
import throttle from "lodash.throttle"
import * as S from "./styled"
import Typography from "../commons/Typography"
import Slider from "../commons/Slider"
import Input from "../commons/Input"
import CustomSelect from "../commons/CustomSelect"
import Col from "../commons/Col"
import Row from "../commons/Row"
import { investmentTermOptions } from "../../helpers/getSelectOptions"
import useDailyValues from "../../helpers/useDailyValues"
import calculateSavingsAmount from "../../helpers/calculateSavingsAmount"
import floatingPointToCommaString from "../../helpers/floatingPointToCommaString"
import getCurrencyConfig from "../../helpers/getCurrencyConfig"
import investmentCalculator from "../../helpers/investmentSimulator"
import getAliquotaImpostoPorInvestimento from "../../helpers/getAliquotaImpostoPorInvestimento"
import getSelectedValueReactSelect from "../../helpers/getSelectedValueReactSelect"
import InvestmentForm from "../commons/InvestmentForm"

function InvestmentCalculator({
  setInvestmentValues,
  setInvestmentsResultsData,
}) {
  const [mensal, setMensal] = useState(0)
  const [investmentCalculatorValues, setInvestmentCalculatorValues] = useState({
    cetip: "",
    poupanca: "",
    inflation: "",
    tempo: 3,
    inicial: 0,
    mensal: 0,
    investment1: {
      ir: "",
      tipo: "",
      percdi: "",
    },
    investment2: {
      ir: "",
      tipo: "",
      percdi: "",
    },
    investment3: {
      ir: "",
      tipo: "",
      percdi: "",
    },
  })

  const [investmentResults, setInvestmentResults] = useState({
    investment1Result: null,
    investment2Result: null,
    investment3Result: null,
  })

  const { cetip, poupanca, inflation } = useDailyValues() // cetip = cdi e inflation = ipca na função

  useEffect(() => {
    setInvestmentValues(investmentCalculatorValues)
    setInvestmentsResultsData(investmentResults)
  }, [investmentCalculatorValues, investmentResults])

  useEffect(() => {
    if (
      investmentCalculatorValues.investment1.ir &&
      investmentCalculatorValues.investment1.tipo &&
      investmentCalculatorValues.investment1.percdi
    ) {
      const investment1Result = investmentCalculator(
        {
          percdi: investmentCalculatorValues.investment1.percdi,
          impostoRenda: getAliquotaImpostoPorInvestimento(
            investmentCalculatorValues.investment1.ir
          ),
          tipo: investmentCalculatorValues.investment1.tipo,
          tempo: investmentCalculatorValues.tempo,
          inicial: investmentCalculatorValues.inicial,
          mensal: investmentCalculatorValues.mensal,
        },
        cetip,
        inflation.accumulated
      )

      setInvestmentResults(oldValues => ({
        ...oldValues,
        investment1Result,
      }))
    }
    if (
      investmentCalculatorValues.investment2.ir &&
      investmentCalculatorValues.investment2.tipo &&
      investmentCalculatorValues.investment2.percdi
    ) {
      const investment2Result = investmentCalculator(
        {
          percdi: investmentCalculatorValues.investment2.percdi,
          impostoRenda: getAliquotaImpostoPorInvestimento(
            investmentCalculatorValues.investment2.ir
          ),
          tipo: investmentCalculatorValues.investment2.tipo,
          tempo: investmentCalculatorValues.tempo,
          inicial: investmentCalculatorValues.inicial,
          mensal: investmentCalculatorValues.mensal,
        },
        cetip,
        inflation.accumulated
      )

      setInvestmentResults(oldValues => ({
        ...oldValues,
        investment2Result,
      }))
    }
    if (
      investmentCalculatorValues.investment3.ir &&
      investmentCalculatorValues.investment3.tipo &&
      investmentCalculatorValues.investment3.percdi
    ) {
      const investment3Result = investmentCalculator(
        {
          percdi: investmentCalculatorValues.investment3.percdi,
          impostoRenda: getAliquotaImpostoPorInvestimento(
            investmentCalculatorValues.investment3.ir
          ),
          tipo: investmentCalculatorValues.investment3.tipo,
          tempo: investmentCalculatorValues.tempo,
          inicial: investmentCalculatorValues.inicial,
          mensal: investmentCalculatorValues.mensal,
        },
        cetip,
        inflation.accumulated
      )

      setInvestmentResults(oldValues => ({
        ...oldValues,
        investment3Result,
      }))
    }
  }, [investmentCalculatorValues])

  const handleInputChange = useCallback((field, value) => {
    setInvestmentCalculatorValues(oldValues => ({
      ...oldValues,
      [field]: value,
    }))
  }, [])

  useEffect(() => {
    if (cetip && poupanca && inflation) {
      setInvestmentCalculatorValues(oldValues => ({
        ...oldValues,
        cetip,
        poupanca,
        inflation: inflation.accumulated,
      }))
    }
  }, [cetip, poupanca, inflation])

  const handleSliderChange = useCallback(
    throttle(value => {
      handleInputChange("inicial", value)
    }, 500),
    []
  )

  const handleChangeInvestmentInput = useCallback((_, m) => {
    handleInputChange("inicial", m)
  }, [])

  const handleChangeMensalValue = useCallback((event, value) => {
    event.preventDefault()
    setMensal(value)
  }, [])

  useEffect(() => {
    handleInputChange("mensal", mensal)
  }, [mensal])

  const handleChangeTempoValue = useCallback(e => {
    handleInputChange("tempo", e.value)
  }, [])

  const handleChangeInvestmentForm = useCallback(
    name => formValues => {
      setInvestmentCalculatorValues(oldValues => ({
        ...oldValues,
        [name]: {
          ...formValues,
        },
      }))
    },
    []
  )

  const {
    typography: { dark, gray },
  } = useTheme()

  const renderTaxesInputsRightContent = () => (
    <Typography type="p" size="14px" weight="300" color={gray}>
      %a.a.
    </Typography>
  )

  return (
    <>
      <S.InvestmentCalculatorContainer id="calculator">
        <S.BlackSquare />
        <S.InvestmentCalculatorHeader>
          <S.OrangeSquare />
          <S.TitleWrapper>
            <Typography textAlign="center" type="h1" size="24px" color={dark}>
              Calculadora de Investimentos
            </Typography>
          </S.TitleWrapper>
        </S.InvestmentCalculatorHeader>
      </S.InvestmentCalculatorContainer>
      <S.InvestmentCalculatorContent>
        <Typography type="p" size="16px" color={dark} weight="bold">
          Quanto você quer investir?
        </Typography>
        <S.SliderValueShower>
          <S.UnitWrapper>
            <Typography type="p" size="20px" weight="300" color={dark}>
              R$
            </Typography>
          </S.UnitWrapper>
          <S.InvisibleInvestmentInput
            value={investmentCalculatorValues.inicial}
            onChange={handleChangeInvestmentInput}
            currency="BRL"
            config={getCurrencyConfig()}
          />
        </S.SliderValueShower>
        <Slider
          onChange={handleSliderChange}
          value={investmentCalculatorValues.inicial}
          min={0}
          max={1000000}
          step={1000}
        />
        <S.ContributionContainer>
          <div>
            <Input
              isCurrency
              currency="BRL"
              config={{
                locale: "pt-BR",
                formats: {
                  number: {
                    BRL: {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              placeholder="R$ 0,00"
              label="Aporte mensal"
              defaultValue={mensal}
              onChange={handleChangeMensalValue}
            />
          </div>
          <CustomSelect
            label="Prazo de investimento"
            selected={getSelectedValueReactSelect(
              investmentTermOptions,
              "3 meses (90 dias)"
            )}
            options={investmentTermOptions}
            onChange={handleChangeTempoValue}
            placeholder="Qual o prazo de investimento?"
          />
        </S.ContributionContainer>
        <Row gap={16}>
          <Col col={4}>
            {cetip && (
              <Input
                defaultValue={floatingPointToCommaString(cetip)}
                onChange={({ currentTarget: { value } }) =>
                  setInvestmentCalculatorValues(old => ({
                    ...old,
                    CDI: value,
                  }))
                }
                max={100}
                placeholder="1,90"
                label="CDI"
                rightContent={renderTaxesInputsRightContent()}
              />
            )}
          </Col>
          <Col col={4}>
            {inflation && (
              <Input
                defaultValue={floatingPointToCommaString(inflation.accumulated)}
                onChange={({ currentTarget: { value } }) =>
                  setInvestmentCalculatorValues(old => ({
                    ...old,
                    inflation: value,
                  }))
                }
                placeholder="2,44"
                label="Inflação"
                rightContent={renderTaxesInputsRightContent()}
              />
            )}
          </Col>
          <Col col={4}>
            {poupanca && (
              <Input
                defaultValue={calculateSavingsAmount(poupanca)}
                onChange={({ currentTarget: { value } }) =>
                  setInvestmentCalculatorValues(old => ({
                    ...old,
                    savings: value,
                  }))
                }
                placeholder="1,40"
                label="Poupança"
                rightContent={renderTaxesInputsRightContent()}
              />
            )}
          </Col>
        </Row>
        <Row gap={16}>
          <InvestmentForm
            handleChange={handleChangeInvestmentForm("investment1")}
            title="Investimento 1:"
          />
          <InvestmentForm
            handleChange={handleChangeInvestmentForm("investment2")}
            title="Investimento 2:"
          />
          <InvestmentForm
            handleChange={handleChangeInvestmentForm("investment3")}
            title="Investimento 3:"
          />
        </Row>
      </S.InvestmentCalculatorContent>
    </>
  )
}

export default InvestmentCalculator

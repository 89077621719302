import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import Button from "../commons/Button"
import Typography from "../commons/Typography"
import * as S from "./styled"

function SpreadsheetStatistics() {
  const {
    typography: { lightOrange },
    colors: { dark },
  } = useTheme()

  return (
    <S.SpreadsheetStatistics>
      <S.BlueSquare />
      <Typography
        width="100%"
        type="p"
        size="24px"
        weight="900"
        color={dark}
        textAlign="center"
        margin="30px 0 0 0"
      >
        Alguns números e contando.
      </Typography>
      <S.DataContainer>
        <S.InvestmentDataWrapper>
          <Typography type="p" size="45px" weight="900" color={lightOrange}>
            +100.020
          </Typography>
          <Typography type="p" size="14px" color={dark}>
            Downloads
          </Typography>
        </S.InvestmentDataWrapper>
        <S.InvestmentDataWrapper>
          <Typography type="p" size="45px" weight="900" color={lightOrange}>
            +50
          </Typography>
          <Typography type="p" size="14px" color={dark}>
            Mil investidores satisfeitos
          </Typography>
        </S.InvestmentDataWrapper>
      </S.DataContainer>
      <Button onClick={() => scrollTo("#form")} bgcolor={lightOrange}>
        <Typography type="p" color={dark} size="14px" weight={700}>
          QUERO RECEBER AGORA
        </Typography>
      </Button>
      <S.NeonSquare />
    </S.SpreadsheetStatistics>
  )
}

export default SpreadsheetStatistics

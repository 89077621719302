import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;

  img:first-child {
    width: 100px;
  }

  img + img {
    width: 150px;
    margin-left: 24px;
  }
`

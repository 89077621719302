import styled, { css } from "styled-components"

const PlaceholderStyles = css`
  color: ${({ theme }) => theme.typography.placeholder};
`

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 0 15px;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    ${PlaceholderStyles}
  }
  &::-moz-placeholder {
    ${PlaceholderStyles}
  }
  &:-ms-input-placeholder {
    ${PlaceholderStyles}
  }
  &:-moz-placeholder {
    ${PlaceholderStyles}
  }
`

export const InputGroup = styled.div`
  margin: 10px 0;
  input {
    background-color: ${({ theme }) => theme.colors.mediumGray};
  }
`

export const Label = styled.label`
  padding: 0 15px;
  line-height: 30px;
  font-size: 14px;
  color: ${({ theme }) => theme.typography.dark};
`

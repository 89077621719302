import React from "react"
import * as S from "./styled"

// TODO: Refatorar componente input e separar em um componente currency, mask e normal;
function Input({
  config,
  currency,
  defaultValue,
  isCurrency,
  label,
  name,
  onChange,
  placeholder,
  rightContent,
  required,
  value,
  inputRef,
}) {
  const InputComponent = isCurrency ? S.CurrencyInput : S.Input

  return (
    <>
      {label && <S.Label htmlFor={name}>{label}</S.Label>}
      <S.InputGroup $hasRightContent={!!rightContent}>
        <InputComponent
          ref={inputRef}
          $hasRightContent={!!rightContent}
          config={config}
          currency={currency}
          name={name}
          id={name}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          required={required}
          value={value}
        />
        {rightContent}
      </S.InputGroup>
    </>
  )
}

export default Input

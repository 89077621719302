import React from "react"
import InputMask from "react-input-mask"
import * as S from "./styled"

function MaskedInput({
  mask,
  value,
  label,
  placeholder,
  onChange,
  formatChars,
  maskChar,
  name,
  required,
}) {
  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      formatChars={formatChars}
      maskChar={maskChar}
      required={required}
    >
      {inputProps => (
        <S.InputGroup>
          <S.Label>
            {label}
            <S.Input
              label={label}
              placeholder={placeholder}
              name={name}
              required={required}
              {...inputProps}
            />
          </S.Label>
        </S.InputGroup>
      )}
    </InputMask>
  )
}

export default MaskedInput

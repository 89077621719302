import React from "react"
import { useTheme } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import Button from "../commons/Button"
import Typography from "../commons/Typography"
import * as S from "./styled"

function InvestmentStatistics() {
  const {
    typography: { lightOrange },
    colors: { dark },
  } = useTheme()

  return (
    <S.InvestimentStatistics>
      <S.WhiteSquare />
      <Typography
        width="100%"
        type="p"
        size="24px"
        weight="900"
        color="white"
        textAlign="center"
        margin="30px 0 0 0"
      >
        Alguns números e contando.
      </Typography>
      <S.DataContainer>
        <S.InvestmentDataWrapper>
          <Typography type="p" size="45px" weight="900" color={lightOrange}>
            89.300
          </Typography>
          <Typography type="p" size="14px" color="white">
            Downloads
          </Typography>
        </S.InvestmentDataWrapper>
        <S.InvestmentDataWrapper>
          <Typography type="p" size="45px" weight="900" color={lightOrange}>
            +2
          </Typography>
          <Typography type="p" size="14px" color="white">
            Milhões de reais
          </Typography>
        </S.InvestmentDataWrapper>
        <S.InvestmentDataWrapper>
          <Typography type="p" size="45px" weight="900" color={lightOrange}>
            +50
          </Typography>
          <Typography type="p" size="14px" color="white">
            Mil investidores satisfeitos
          </Typography>
        </S.InvestmentDataWrapper>
      </S.DataContainer>
      <Button onClick={() => scrollTo("#calculator")} bgcolor={lightOrange}>
        <Typography type="p" color={dark} size="14px" weight={700}>
          Quero fazer a simulação
        </Typography>
      </Button>
      <S.BlueSquare />
    </S.InvestimentStatistics>
  )
}

export default InvestmentStatistics

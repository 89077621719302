import styled, { css } from "styled-components"

const BaseTypographyStyles = ({
  color,
  width,
  weight,
  lineHeight,
  size,
  margin,
  textAlign,
}) => css`
  margin: 0;
  color: ${color};
  font-weight: ${weight};
  ${textAlign && `text-align: ${textAlign};`}
  ${lineHeight && `line-height: ${lineHeight};`}
  ${size && `font-size: ${size};`}
  ${width && `width: ${width}`};
  & b {
    font-weight: bold;
  }
  ${margin && `margin: ${margin};`}
`

export const Paragraph = styled.p`
  ${BaseTypographyStyles}
`

export const TextHeader = styled.h1`
  ${BaseTypographyStyles}
`

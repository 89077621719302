import styled from "styled-components"
import { customMedia } from "../../styles/customMedia"

export const TheHabitOfInvesting = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
  color: ${({ theme }) => theme.colors.dark};
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 30px;

  iframe {
    z-index: 1;
    margin: 20px 0;
  }

  ${customMedia.greaterThan("mediumDesktop")`
    padding: 60px;
    align-items: center;

    & > h1 {
      font-size: 94px;
      line-height: 1;
    }

    & > p {
      font-size: 28px;
      line-height: 44px;
      width: 70%;
      margin: 20px 0;
    }
    
    iframe {
      width: 819px;
      height: 460px;
    }
  `}
  ${customMedia.greaterThan("hugeDesktop")`
    iframe {
      width: 1024px;
      height: 576px;
    }
  `}
`

export const BlueSquare = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.info};
  ${customMedia.greaterThan("mediumDesktop")`
    top: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
  `}
`

export const OrangeSquare = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 26px;
  height: 26px;
  background-color: ${({ theme }) => theme.colors.lightOrange};
  ${customMedia.greaterThan("mediumDesktop")`
    bottom: 90px;
    left: 90px;
    width: 115px;
    height: 115px;
  `}
`

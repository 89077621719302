export const defaultTheme = {
  colors: {
    lightBeige: "#E5E5E5",
    darkBeige: "#EBE5D6",
    neon: "#31E890",
    dark: "#151B1E",
    orange: "#F75630",
    lightOrange: "#ED8E53",
    lightGreen: "#3AB77A",
    info: "#4041F4",
    darkBlue: "#0D1142",
    mediumBlue: "#000081",
    success: "#198754",
    error: "#dc3545",
    gray: "#F7F7F7",
    mediumGray: "#EBEAEF",
    blueLink: "#239DF7",
  },
  typography: {
    gray: "#C2C2C2",
    light: "#EBE5D6",
    dark: "#151B1E",
    info: "#4041f4",
    error: "#dc3545",
    success: "#198754",
    lightOrange: "#ED8E53",
    placeholder: "#a4b0be",
    sizes: {
      header: "36px",
    },
  },
  breakpoints: {
    tablet: "768px",
    smallDesktop: "1024px",
    mediumDesktop: "1280px",
    largeDesktop: "1440px",
    hugeDesktop: "1600px",
  },
}

import React, { useRef, useCallback } from "react"
import { useTheme } from "styled-components"
import * as S from "./styled"
import { investorRangeOptions } from "../../helpers/getSelectOptions"
import Input from "../commons/Input"
import MaskedInput from "../commons/MaskedInput"
import Button from "../commons/Button"
import Typography from "../commons/Typography"
import CustomSelect from "../commons/CustomSelect"
import Col from "../commons/Col"
import Row from "../commons/Row"
import {getReturnUrlGatsby, getFullUrl, getUrlParamByName} from '../../utils/functions/UrlAndQuerys'

function SpreadsheetForm() {
  const formRef = useRef(null)

  const {
    typography: { dark },
    colors: { lightOrange },
  } = useTheme()

   const handleSubmitForm = useCallback(
    event => {
      event.preventDefault()
      if (formRef.current) {
        formRef.current.submit()
      }
    },
    [formRef]
  )

  return (
    <S.SpreadsheetForm
      ref={formRef}
      method="POST"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      onSubmit={handleSubmitForm}
      id="form"
    >
      <input type="hidden" name="oid" value="00D6g0000054aoB"></input>
      <input type="hidden" id="retURL" name="retURL" value={getReturnUrlGatsby('obrigado-planilha?source=spreadsheets')} />
      <input
        type="hidden"
        id="00N6g00000TXYQe"
        maxLength="255"
        name="00N6g00000TXYQe"
        size="20"
        value="planilha-analise-de-ativos"
      />
      <input
        type="hidden"
        id="lead_source"
        maxLength="255"
        name="lead_source"
        size="20"
        value="leadmagnet-alwayson"
      />
      <input
        type="hidden"
        id="00N4V00000D7Ntk"
        maxLength="255"
        name="00N4V00000D7Ntk"
        size="20"
        value={getFullUrl()}
      />
      <input
        type="hidden"
        id="lead_source"
        maxLength="255"
        name="lead_source"
        size="20"
        value="leadmagnet-alwayson"
      />

      <input
        type="hidden"
        id="00N6g00000TXYQh"
        maxLength="255"
        name="00N6g00000TXYQh"
        size="20"
        value=""
      />
      <input
        type="hidden"
        id="00N6g00000TXYQu"
        maxLength="255"
        name="00N6g00000TXYQu"
        size="20"
        value={getUrlParamByName('utm_campaign')}
      />
      <input
        type="hidden"
        id="00N6g00000TXYQv"
        maxLength="255"
        name="00N6g00000TXYQv"
        size="20"
        value={getUrlParamByName('utm_content')}
      />
      <input
        type="hidden"
        id="00N6g00000TXYQw"
        maxLength="255"
        name="00N6g00000TXYQw"
        size="20"
        value={getUrlParamByName('utm_medium')}
      />
      <input
        type="hidden"
        id="00N6g00000TXYQx"
        maxLength="255"
        name="00N6g00000TXYQx"
        size="20"
        value={getUrlParamByName('utm_source')}
      />
      <input
        type="hidden"
        id="00N6g00000TXYQy"
        maxLength="255"
        name="00N6g00000TXYQy"
        size="20"
        value={getUrlParamByName('gclid')}
      />
      <input
        type="hidden"
        id="00N6g00000UV3FN"
        maxLength="255"
        name="00N6g00000UV3FN"
        size="20"
        value={getUrlParamByName('assessor')}
      />

      <S.BlackSquare />
      <S.HeaderWrapper>
        <Typography type="p" size="14px" lineHeight="24px" color={dark}>
          Para enviar a planilha, preciso de algumas informações.{" "}
          <b>Vamos lá?</b>
        </Typography>
      </S.HeaderWrapper>
      <Row gap={16}>
        <Col col={12} md={6}>
          <Input
            required
            placeholder="Informe aqui"
            label="Nome"
            id="last_name" maxlength="80" name="last_name" size="20" type="text"
          />
        </Col>
        <Col col={12} md={6}>
          <Input
            required
            placeholder="Informe aqui"
            label="E-mail"
            id="email" maxlength="80" name="email" size="20" type="text"
          />
        </Col>
      </Row>
      <Row gap={16}>
        <Col col={12} md={6}>
          <MaskedInput
            mask="(99) 9999-9999?"
            maskChar=""
            formatChars={{
              9: "[0-9]",
              "?": "[0-9]",
            }}
            placeholder="Informe aqui"
            label="Telefone"
            required
            id="mobile" maxlength="40"  name="mobile" size="20" type="text"
          />
        </Col>
        <Col col={12} md={6}>
          <CustomSelect
            label="Em qual das faixas de investidor você se encaixa?"
            options={investorRangeOptions}
            id="00N6g00000TXYQi" name="00N6g00000TXYQi" title="Liquidez RD"
            required
          />
        </Col>
      </Row>
      <S.DiagnosisButtonWrapper>
        <Button bgcolor={lightOrange}>
          <Typography type="p" color={dark} weight={600} size="14px">
            RECEBER AGORA
          </Typography>
        </Button>
      </S.DiagnosisButtonWrapper>
      <S.PrivacyWrapper>
        <Typography type="p" size="10px">
          Ao clicar no botão, você concorda com nossos{" "}
          <a href="https://www.euqueroinvestir.com/politica-de-privacidade/">
            Termos de uso
          </a>{" "}
          e
          <a href="https://www.euqueroinvestir.com/politica-de-privacidade/">
            {" "}
            Política de Privacidade
          </a>
          , incluindo o uso de cookies e o envio de comunicações.
        </Typography>
      </S.PrivacyWrapper>
    </S.SpreadsheetForm>
  )
}

export default SpreadsheetForm

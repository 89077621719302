import React from "react"
import * as S from "./styled"
import ArrowDownIcon from "../../../../static/down-arrow.svg"

function ArrowDown({ image, onClick }) {
  return (
    <S.Button type="button" bgcolor="transparent" onClick={onClick}>
      <S.ArrowDownAnimated src={image || ArrowDownIcon} alt="" />
    </S.Button>
  )
}

export default ArrowDown

import styled from "styled-components"
import { DefaultSafeAreaPadding } from "../../styles/styled-defaults"
import { customMedia } from "../../styles/customMedia"

export const LeadForm = styled.form`
  ${DefaultSafeAreaPadding}
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 50px;
  position: relative;

  ${customMedia.greaterThan("mediumDesktop")`
    margin-top: 100px;
    padding-bottom: 80px;
    padding-left: 22%;
    padding-right: 22%;

    label {
      font-size: 14px;
    }
  `}

  ${customMedia.greaterThan("hugeDesktop")`
    padding-left: 25%;
    padding-right: 25%;

    label {
      font-size: 14px;
    }
  `}
`

export const HeaderWrapper = styled.div`
  padding-bottom: 20px;

  ${customMedia.greaterThan("mediumDesktop")`
    & > p {
      font-size: 28px;
      text-align: center;
      margin-bottom: 32px;
    }
  `}
`

export const RadioGroup = styled.div`
  padding: 20px 15px 0;

  ${customMedia.greaterThan("mediumDesktop")`
    padding: 0;
    p {
      padding: 0 15px;
      font-size: 12px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  `}
  ${customMedia.greaterThan("hugeDesktop")`
    p {
      font-size: 14px;
    }
  `}
`

export const PrivacyWrapper = styled.div`
  direction: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${customMedia.greaterThan("mediumDesktop")`
    p {
      font-size: 12px;
    }
  `}
  ${customMedia.greaterThan("hugeDesktop")`
    p {
      font-size: 14px;
    }
  `}
`

export const TypographyWrapper = styled.div`
  margin-left: 20px;
`

export const DiagnosisButtonWrapper = styled.div`
  margin: 25px 0;
`

export const BlueSquare = styled.div`
  display: none;
  width: 95px;
  height: 95px;
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.info};
  `}
`

export const GreenSquare = styled.div`
  display: none;
  width: 75px;
  height: 75px;
  ${customMedia.greaterThan("mediumDesktop")`
    display: block;
    position: absolute;
    bottom: 100px;
    right: 0;
    background-color: ${({ theme }) => theme.colors.neon};
  `}
`

import styled from "styled-components"
import ReactSlider from "react-slider"

export const Slider = styled(ReactSlider)`
  width: 100%;
  height: 5px;
`
export const Wrapper = styled.div`
  height: 40px;
`

export const Thumb = styled.div`
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.colors.info};
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  top: -10px;
  outline: none;
  z-index: 99;
`

export const Track = styled.div`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 1 ? "#ddd" : props.theme.colors.info};
  border-radius: 999px;
`
